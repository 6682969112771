<script setup lang="ts">
import useFooterState from '~core/components/Layout/TheFooter/useFooterState';

const {
    isEnabled: isFooterEnabled,
} = useFooterState();
</script>

<template>
    <div>
        <NuxtLayout name="base">
            <div
                class="
                    grid
                    h-full
                    grid-rows-[min-content_auto_min-content]
                    bg-background-3
                "
            >
                <div class="z-20 col-span-full row-span-1 row-start-1">
                    <slot name="header">
                        <TheSubHeader />
                    </slot>
                </div>

                <div class="col-span-full row-span-2 row-start-1">
                    <slot name="main">
                        <NuxtPage />
                    </slot>
                </div>

                <div :class="isFooterEnabled ? 'h-[72px] md:h-[88px]' : 'h-0'">
                    <slot name="footer">
                        <TheFooter />
                    </slot>
                </div>
            </div>
        </NuxtLayout>
    </div>
</template>
