<script setup  lang="ts">

</script>

<template>
    <div class="flex h-10 items-center border-b border-b-shade-1 bg-background-1 px-container-gap">
        <div class="flex items-center space-x-3 typo-meta-s-medium">
            <Icon name="tot:caret-left" class="block size-4" />
            <span>asd</span>
            <span>/</span>
            <span>Buchungsbelege</span>
        </div>
    </div>
</template>
